import axios from "axios"
import { Toast } from "vant"
import store from "@/store"
import router from "@/router"

const baseURL: string = "https://bi.nbaiyouwei.com/index.php/"

const instance = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    "Content-Security-Policy": "upgrade-insecure-requests"
  }
})

export const get = (url: string, data = {}) => {
  return instance.get(url, { params: data })
}

export const post = (url: string, data = {}) => {
  return instance.post(url, data)
}

// 请求拦截器
instance.interceptors.request.use(
  config => {
    // 转form-data 格式
    let ret = ""
    for (const it in config.data) {
      ret += encodeURIComponent(it) + "=" + encodeURIComponent(config.data[it]) + "&"
    }
    ret = ret.substring(0, ret.lastIndexOf("&"))
    config.data = ret
    return config
  },
  error => Promise.reject(error)
)

instance.interceptors.response.use(
  response => {
    const data = response.data
    if (data.code !== 200) {
      Toast(data.msg)
      return Promise.reject(new Error(data.msg))
    } else {
      return data.data
    }
  },
  error => {
    console.log(error.response)
    if (error.response.data.code === 401) {
      // 未登录
      const err = "请先登录"
      store.commit("logout")
      router.replace({ name: "login" }).then(() => {})
      Toast(err)
      return Promise.reject(new Error(err))
    } else if (error.response.data.code === 403) {
      // 无权限
      const err = "您没有权限查看"
      Toast(err)
      return Promise.reject(new Error(err))
    }
    Toast(error.message)
    return Promise.reject(error)
  }
)
